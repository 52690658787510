<template>
  <div class="mt-3 text-grey1 mx-auto px-5 py-8 sm:w-80 lg:max-w-2xl">
    <div>Share this link to invite your colleagues and friends:</div>

    <div class="flex mt-2 mb-6">
      <div
        class="flex flex-col flex-grow justify-center h-9 select-text select-all bg-grey6 rounded px-3 text-grey2 overflow-hidden whitespace-nowrap overflow-ellipsis font-normal cursor-text focus:overflow-clip focus:outline-none focus:ring-2 focus:ring-grey5"
      >
        <div class="truncate">{{ signinURL }}</div>
      </div>

      <!-- Copy icon -->
      <div
        class="flex flex-col justify-center bg-grey6 rounded-r h-9 px-3 w-auto border border-solid border-grey5 dark:border-grey6 border-t-0 border-r-0 border-b-0 cursor-pointer font-medium text-base2"
        @click="copyLink"
        title="Copy link"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          class="text-base2 hover:opacity-75"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect
            stroke="currentColor"
            x="8"
            y="8"
            width="12"
            height="12"
            rx="2"
          />
          <path
            stroke="currentColor"
            d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
          />
        </svg>
      </div>
    </div>
    Or, invite via email (one address on each line):
    <div class="flex mt-2 mb-3">
      <textarea
        rows="5"
        class="w-full px-3 py-1 rounded bg-grey6 text-grey2 font-normal select-text select-all"
        v-model="mailList"
      ></textarea>
    </div>

    <div>
      <m-button
        @click="sendReferralEmail(code)"
        class="mb-4"
        button-style="btn-primary"
        segmentName="Send invitation"
        :pending="sendingEmails"
        icon="💌"
      >
        Send invites
      </m-button>
      <div v-if="accountPlan < 2">
      PS: For every new user you refer, you will get <a-link segmentName="meetricPro" href="https://meetric.notion.site/Meetric-Pro-231289c089e948b79f235c006e83c582">Meetric Pro</a-link> for <span class="font-bold">free</span> for 30 days. For example, if 3 people use your referral link and start using Meetric, you will get 3x30 = 90 days of Meetric Pro for free!
    </div>
    </div>
  </div>
</template>
<script>
import { referral, referralEmail } from '@/api/Cherry';
import MButton from '@/components/UI/MButton';
import ALink from '@/components/UI/ALink';
export default {
  name: 'Referral',
  components: {
    MButton,
    ALink,
  },
  data() {
    return {
      code: '',
      mailList: [],
      sendingEmails: false,
    };
  },
  mounted() {
    referral()
      .then((r) => (this.code = r.referral_code))
      .catch((e) => console.error(e));
  },
  computed: {
    signinURL() {
      return 'https://my.meetric.app/signin?ref=' + this.code;
    },
    accountPlan() {
      return this.$store.getters['plan'];
    },
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.signinURL).then(
        function () {
          const trackProps = {
            type: 'copy',
          };
          window.meetric.track('Refer', trackProps);
          this.$snack({ text: 'Link copied', emoji: '🩹' });
        }.bind(this),
        function (err) {
          console.error('Could not copy text to clipboard', err);
        }
      );
    },
    sendReferralEmail(code) {
      this.sendingEmails = true;
      if (this.mailList.length == 0) {
        alert('Please provide email addresses');
        this.sendingEmails = false;
        return;
      }
      let m = this.mailList.split('\n');
      let invalidMails = m
        .filter((f) => !this.isValidEmail(f))
        .filter((f) => f != '');
      if (invalidMails.length > 0) {
        let alertText = 'These email addresses are not valid:';
        invalidMails.forEach((a) => (alertText += '\n' + a));
        alert(alertText);
        this.sendingEmails = false;
        return;
      }
      let validMails = m.filter((f) => this.isValidEmail(f));
      this.mailList = validMails.join('\n');
      if (validMails.length == 0) {
        alert('No valid emails');
        this.sendingEmails = false;
        return;
      }
      const trackProps = {
        type: 'email',
      };
      referralEmail(validMails, code)
        .then(() => {
          this.sendingEmails = false;
          this.$snack({ text: 'Referral mail(s) sent!', emoji: '💌' });
          this.mailList = [];
          window.meetric.track('Refer', trackProps);
        })
        .catch((e) => console.error(e));
    },
    isValidEmail(email) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
        email
      );
    },
  },
};
</script>
